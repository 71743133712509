const data = [
    {
        text: 'Mitarbeiter',
        value: 'full_name',
        align: 'center'
    },
    {
        text: 'Personalnummer',
        value: 'personal_number',
        align: 'center'
    },
    {
        text: 'Tagwerk',
        value: 'hours',
        align: 'center'
    },
    {
        text: 'Arbeitszeit',
        value: 'total_hours',
        align: 'center'
    },
    {
        text: '#Abwesenheit',
        value: 'days_missing',
        align: 'center'
    },
    {
        text: '#Feiertage',
        value: 'days_in_holidays',
        align: 'center'
    },
    {
        text: '#Tage im Monat',
        value: 'days_in_month',
        align: 'center'
    },
    {
        text: '#Tage Polierer',
        value: 'days_approved_foreman',
        align: 'center',
    },
    {
        text: '#Tage Bauleiter',
        value: 'days_approved_manager',
        align: 'center',
        permissions: ['VE', 'A', 'BA', 'BB', 'BD']
    },
    {
        text: '#Tage Verwaltung',
        value: 'days_approved_admin',
        align: 'center',
        permissions: ['VE', 'A']
    },
    {
        text: 'Approved by',
        value: 'initials',
        align: 'center',
    },
    {
        text: 'Freizeichnen',
        value: 'actions',
        align: 'center'
    },
]

export default data;