<template>
    <div>
        <v-app-bar id="top-header"
                   class="top-header"
                   app
                   :style="$vuetify.breakpoint.smAndUp ? 'top:104px;height:auto' : 'top:57px;height:auto' "
                   height="170"
                   min-height="170"
        >
            <v-container>
                <v-row>
                    <v-col cols="12" xs="12">
                        <v-toolbar-title>BlCheckoff</v-toolbar-title>
                    </v-col>

                    <v-col cols="12" sm="2" v-if="['PO', 'BA', 'BB', 'BD'].includes(getMe().user.role)">
                        <zw-autocomplete label="Baustellen"
                                         v-model="filter.construction_zone_id"
                                         clearable
                                         item-text="label"
                                         item-value="id"
                                         :items="constructionZonesList"
                                         :custom-option-label="true"
                        ></zw-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" v-if="['VE', 'A'].includes(getMe().user.role)">
                        <zw-autocomplete label="Betriebsstätten"
                                         v-model="filter.department_id"
                                         clearable
                                         item-text="label"
                                         item-value="id"
                                         :items="departmentsList"
                                         :custom-option-label="true"
                        ></zw-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-btn v-if="!isRole('PR')" @click="toTimeManagement(0)" color="primary" class="mr-2">
                            Zeitverwaltung
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-autocomplete
                            :search-input.sync="search"
                            :items="searchItems"
                            :loading="searchLoading"
                            @change="employeeSelected"
                            hide-no-data
                            hide-selected
                            item-text="label"
                            item-value="id"
                            return-object
                            dense
                            x-small
                            append-icon="mdi-magnify"
                            outlined
                            hide-details
                        ></v-autocomplete>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="6" sm="2">
                        <v-select dense
                                  reverse
                                  v-model="month"
                                  :items="months"
                                  @change="monthChanged"
                        ></v-select>
                    </v-col>
                    <v-col cols="6" sm="2">
                        <v-select dense
                                  reverse
                                  v-model="year"
                                  :items="years"
                                  @change="yearChanged"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-chip close
                                class="mr-2"
                                v-if="filter.construction_zone_id"
                                color="#94C12D"
                                text-color="white"
                                @click:close="filter.construction_zone_id = null"
                        >
                            {{
                                constructionZonesList ?
                                    constructionZonesList.find(cz => cz.id == filter.construction_zone_id).custom_label : ''
                            }}
                        </v-chip>
                        <v-chip close
                                class="mr-2"
                                v-if="filter.department_id"
                                color="#94C12D"
                                text-color="white"
                                @click:close="filter.department_id = null"
                        >
                            {{ departmentsList ? departmentsList.find(d => d.id == filter.department_id).custom_label : '' }}
                        </v-chip>
                        <v-chip close
                                class="mr-2"
                                v-if="new Date().getFullYear() != year"
                                color="#94C12D"
                                text-color="white"
                                @click:close="year = new Date().getFullYear()"
                        >
                            {{ this.year }}
                        </v-chip>
                        <v-chip close
                                class="mr-2"
                                v-if="new Date().getMonth() + 1 != month"
                                color="#94C12D"
                                text-color="white"
                                @click:close="month = new Date().getMonth() + 1"
                        >
                            {{ months.find(m => m.value == month).text || '' }}
                        </v-chip>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>

        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-row>
            <v-col>
                <div class="custom-table">
                    <v-data-table
                        :headers="columns"
                        :items="tableData"
                        :loading="loading"
                        class="elevation-1"
                        hide-default-footer
                        :items-per-page="tableData.length"
                    >
                        <template v-slot:item.full_name="{ item }">
                            <div>
                                <a @click.prevent="$root.$children[0].$refs['employee-form'].open(item.id, true)"
                                   href="#">{{ item.full_name }}</a>
                            </div>
                        </template>

                        <template v-slot:item.days_approved_foreman="{item}">
                        <span
                            :class="item.days_approved_foreman < item.days_in_month ? 'error--text' : 'success--text'">
                            {{ item.days_approved_foreman }}
                        </span>
                        </template>
                        <template v-slot:item.days_approved_manager="{item}">
                        <span
                            :class="item.days_approved_manager < item.days_in_month ? 'error--text' : 'success--text'">
                            {{ item.days_approved_manager }}
                        </span>
                        </template>
                        <template v-slot:item.days_approved_admin="{item}">
                        <span :class="item.days_approved_admin < item.days_in_month ? 'error--text' : 'success--text'">
                            {{ item.days_approved_admin }}
                        </span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn @click="toTimeManagement(item.id)"
                                   small
                                   icon
                                   color="success"
                            >
                                <v-icon v-if="isApprovedByRole(item, getMe().user.role)">
                                    mdi-eye
                                </v-icon>
                                <v-icon v-else>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {employeesList} from './../graphql/employee'
import {constructionZonesList} from './../graphql/constructionZone'
import {departmentsList} from './../graphql/department'
import {checkout} from './../graphql/worklog'
import worklogsCommon from '@/mixins/worklogsCommon'
import headersData from '@/headers/checkout'
import debounce from 'debounce'

export default {
    mixins: [worklogsCommon],
    apollo: {
        constructionZonesList: {
            query: constructionZonesList,
            variables: {
                use: true,
            },
            update: function (data) {
                return data.constructionZonesList.map(zone => {
                    zone.label = zone.construction_zone_number
                    zone.custom_label = zone.construction_zone_number + ' ' + zone.name
                    return zone
                })
            },
            fetchPolicy: 'network-only'
        },
        departmentsList: {
            query: departmentsList,
            update: function (data) {
                return data.departmentsList.map(department => {
                    department.label = department.number
                    department.custom_label = department.number + ' ' + department.name
                    return department
                })
            },
            fetchPolicy: 'network-only'
        }
    },
    data() {
        return {
            loading: false,
            opened: false,
            employeesList: [],
            filter: {
                construction_zone_id: null,
                department_id: null,
            },
            tableData: [],
            search: null,
            searchLoading: false,
            searchItems: [],
            selected_employee_id: null,
        }
    },
    computed: {
        columns: function () {
            return headersData
                .filter(col => {
                    if (col.permissions) {
                        if (col.permissions.includes(this.getMe().user.role)) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                })
        },
    },
    methods: {
        calculateData() {
            this.loading = true

            localStorage.setItem('filters', JSON.stringify({
                filter: this.filter,
                opened: this.opened,
                selected_employee_id: this.tableData[0] ? this.tableData[0].id : null,
                year: this.year,
                month: this.month,
            }))

            this.$apollo.query({
                query: checkout,
                variables: {
                    date: this.year + '-' + this.month + '-01',
                    construction_zone_id: this.filter.construction_zone_id,
                    department_id: this.filter.department_id,
                },
                fetchPolicy: 'network-only'
            }).then((result) => {
                this.tableData = JSON.parse(result.data.checkout)
            }).finally(() => {
                this.loading = false
            })
        },
        getMe() {
            return this.$root.$children[0].me;
        },
        isRole(role) {
            return this.$root.$children[0].me.user.role == role;
        },
        toTimeManagement(employee_id) {
            if (!employee_id) {
                employee_id = this.tableData[0] ? this.tableData[0].id : null
            }
            localStorage.setItem('filters', JSON.stringify({
                filter: this.filter,
                opened: this.opened,
                selected_employee_id: employee_id,
                year: this.year,
                month: this.month,
            }))

            this.$router.push({name: 'TimeManagement', 'query': {'employee_id': employee_id}})
        },
        isApprovedByRole(row, role) {
            if (['VE', 'A'].includes(role)) {
                return row.days_approved_admin >= row.days_in_month
            }
            if (['PO'].includes(role)) {
                return row.days_approved_foreman >= row.days_in_month
            }
            if (['BA', 'BB', 'BD'].includes(role)) {
                return row.days_approved_manager >= row.days_in_month
            }

            return false
        },
        makeSearch(search) {
            this.searchLoading = true
            this.$apollo.query({
                query: employeesList,
                fetchPolicy: 'network-only'
            }).then(({data}) => {
                this.searchItems = data.employeesList.map(employee => {
                    return {
                        id: employee.id,
                        label: employee.firstname + ' ' + employee.lastname + ' ' + employee.personal_number
                    }
                })
            }).finally(() => {
                this.searchLoading = false
            })
        },
        employeeSelected(option) {
            this.toTimeManagement(option.id)
        }
    },
    mounted: function () {
        this.selected_employee_id = this.getMe().id
        // this.updateBottomMenu()

        let filter = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : null
        if (filter) {
            this.filter = filter.filter
            this.selected_employee_id = filter.selected_employee_id

            this.year = filter.year
            this.month = filter.month
            this.opened = filter.opened

            this.calculateData()
        }
    },
    watch: {
        'filter.construction_zone_id': function () {
            this.calculateData()
        },
        'filter.department_id': function () {
            this.calculateData()
        },
        search(value) {
            if (!value) {
                return
            }
            debounce(this.makeSearch, 200)(value)
        },
    },
}
</script>

<style lang="scss">
.top-header .v-toolbar__content {
    height: auto !important;
}

.custom-table {
    .v-data-table {
        th {
            top: var(--header-top);
        }
    }
}

.v-main.padding {
    padding-top: var(--header-top) + ' !important';
}

.v-main.mobile-padding {
    padding-top: var(--header-top) + ' !important';
}
.custom-table td {
    padding: 0 0 !important;
}

.custom-table thead th {
    position: sticky;
    z-index: 2;
}

.custom-table thead th:first-child {
    left: 0px;
    z-index: 3;
}

tbody tr td:first-child {
    position: sticky;
    background: white;
    z-index: 1;
    left: 0px;
}

.v-data-table__wrapper {
    overflow-x: unset;
    overflow-y: unset;
}

</style>